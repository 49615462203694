// HACK: The search-title is a house of cards. Lots of tweaking pixels to get the right alignment.
.search-title {
  display: inline-block;
  background: $white;
  border: 1px solid lighten($gray--medium, 5%);
  border-radius: 3px;
  padding: $base--spacing / 3;
  @include breakpoint(650px) {
    padding: 0;
  }
  .title-box {
    display: block;
    float: left;
  }

}

.search-title__query {
  margin-left: $base--spacing;
  @include font-size(18px);
  margin-bottom: 0;
  padding: 0 $base--spacing;
  color: darken($gray--medium, 10%);
  font-family: $base--font-family;
  font-style: italic;
  display: block;
  float: left;
  margin-top: 2px;

}

.post-list {
  padding: 0;
  &.post-list--side-by-side {
    border-bottom: 3px solid $gray--medium;
    @include breakpoint(800px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  article {
    padding: $base--spacing * 1.5 $base--spacing $base--spacing / 2;
    border-bottom: 1px solid $gray--medium;
    @include breakpoint(800px) {
      padding-left: $base--spacing * 6;
      padding-right: $base--spacing * 6;
    }
    &.post-list__even {
      @include breakpoint(800px) {
        width: 50%;
        float: left;
        padding-left: $base--spacing * 2;
        display: block; // cancel out flexbox
      }
    }
    &.post-list__odd {
      @include breakpoint(800px) {
        width: 50%;
        float: left;
        clear: both;
        border-right: 1px solid $gray--medium;
        padding-right: $base--spacing * 2;
        display: block; // cancel out flexbox
        &:last-child {
          width: 100%;
          padding-right: 25%;
          padding-left: 25%;
          border-right: 0;
        }
      }
    }
    &.post-list__first {
      width: 100%;
      border-right: 0;
      @include breakpoint(800px) {
        text-align: center;
      }
      .entry-title {
        @include font-size(21px);
        margin-bottom: $base--spacing / 2;
        @include breakpoint(800px) {
          @include font-size(48px);
          margin-bottom: $base--spacing / 2;
        }
      }
    }
    &.post-list__second {
      width: 100%;
      .entry-title {
        @include font-size(21px);
        margin-bottom: $base--spacing / 2;
        @include breakpoint(800px) {
          @include font-size(36px);
          margin-bottom: $base--spacing / 2;
        }
      }
    }
    .list--icons {
      margin-bottom: 0;
      width: 100%;
      min-height: 50px;
    }
    .entry-title {
      @include font-size(21px);
      margin-bottom: $base--spacing / 2;
      font-family: $base--font-family;
      font-weight: bold;
      a {
        color: $secondary--blue;
        text-decoration: none;
      }
    }
    .posted-on {
      margin-bottom: $base--spacing / 2;
      display: block;
      a {
        font-family: $secondary--font-family__bold;
        text-decoration: none;
        color: lighten($gray--dark, 15%);
        text-transform: uppercase;
      }
    }
  }

}
