.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
  &::before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
  }
}
//for JavaScript. Above doesn't work if you want to show it after DOM load.
.hide,
.hidden {
  display: none;
}

.centered {
  text-align: center;
}

.show {
  display: block;
}

.crossed {
  text-decoration: line-through;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

.invisible {
  visibility: hidden;
}

.clearfix,
.cf {
  *zoom: 1;
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

.clear {
  clear: both;
}

.black {
  color: $black;
  border-color: $black;
}

.white {
  color: $white;
  border-color: $white;
}

.static {
  position: static;
}
