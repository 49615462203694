.services {
  padding-top: $base--spacing * 2;
  padding-bottom: $base--spacing * 2;
  text-align: center;
}

.services__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: $width--12;

  .btn {
    max-width: 250px;
  }

  @include breakpoint(775px) {
    width: $width--6;
  }
}

.services__header {
  @include font-size(21px, false);
  color: $green--light;
  font-family: $base--font-family;
  font-weight: $font-weight__bold;
  line-height: 1.3;
  padding: 0 $base--spacing;

  @include breakpoint(775px) {
    @include font-size(30px, false);
  }
}

.services__title {
  @include font-size(18px, false);
  line-height: 1.4;
  color: $white;
  font-family: $secondary--font-family__light;

  @include breakpoint(775px) {
    @include font-size(25px, false);
  }
}

.services__title--h4 {
  @include font-size(25px, false);
  color: $green--light;
  font-family: $secondary--font-family__light;
  text-transform: uppercase;
  margin-bottom: 0;
}

.our-services {
  padding: $base--spacing * 2 0;
  text-align: center;
}

.our-services__title {
  @include font-size(32px);
  font-family: $secondary--font-family__bold;
  text-transform: uppercase;
}

.our-services__description {
  @include font-size(18px);
  margin: 0 auto;
  max-width: 830px;
}

.our-services__languages {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: $base--spacing * 2 0;
  width: 100%;

  li {
    margin: 0 $base--spacing / 2 $base--spacing;
    max-width: 115px;
  }

  svg {
    width: 100%;
  }

  @include breakpoint(775px) {
    li {
      margin: 0 $base--spacing / 2;
    }
  }
}

.services-template__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: $base--spacing 0 0;
  width: 100%;

  @include breakpoint(600px) {
    .well {
      flex-grow: 2;
      margin: 0 $base--spacing $base--spacing * 2;
      width: 28%;
    }
  }
}

.services-template__title {
  border-bottom: 1px solid $gray--light;
  padding: $base--spacing;
}

.services-template__container {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 $base--spacing;

  svg {
    margin: 0 0 $base--spacing / 2;
  }
}