
div.wpcf7-response-output {
  @extend .alert;
  margin-top: $base--spacing;
}

div.wpcf7-mail-sent-ok {
  @extend .alert-success;
}

div.wpcf7-mail-sent-ng {
  @extend .alert-info;
}

div.wpcf7-spam-blocked {
  @extend .alert-danger;
}

div.wpcf7-validation-errors {
  @extend .alert-danger;
}

span.wpcf7-form-control-wrap {
  position: relative;
}

span.wpcf7-not-valid-tip {
  color: #f00;
  font-size: 1em;
  display: block;
}

span.wpcf7-list-item {
  margin-left: .5em;
}

.wpcf7-display-none {
  display: none;
}

div.wpcf7 img.ajax-loader {
  border: none;
  vertical-align: middle;
  margin-left: 4px;
}

div.wpcf7 div.ajax-error {
  display: none;
}

div.wpcf7 .placeheld {
  color: #888;
}

.screen-reader-response {
  @extend .visuallyhidden;
}
