// Images
//
// Styleguide 11.0.0

// Image
//
// Markup:
// <img src="http://placehold.it/300x200/">
//
// Styleguide 11.1.0
img {
	height: auto;
	max-width: 100%;
  // border: 1px solid $base--color;
	// padding: $base--spacing/2;
}
// Image (no border)
//
// Markup:
// <img class="img--no-border" src="http://placehold.it/300x200/">
//
// Styleguide 11.2.0
.img--no-border {
  border: 0;
	padding: 0;
}
img[src*=".svg"] {
	max-height: 100%;
}
