.error404 {
  background-color: $secondary--purple-dark;
  .wrap--small-gradient {
    background: none;
    &::after {
      display: none;
    }
  }
  .wrap--404 {
    background-image: url("../img/404.svg");
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: $base--spacing;
    padding-bottom: $base--spacing;
    @include breakpoint(600px) {
      padding-top: $base--spacing * 4;
      padding-bottom: $base--spacing * 4;
    }
  }
  .btn {
    background: $secondary--teal;
  }
  .wrap--green-dark {
    background: none;
    color: $white;
  }
  .wrap--search {
    background: darken($secondary--purple-dark, 13%);
  }
  .form__submit {
    path {
      fill: $secondary--purple-dark;
    }
  }
  .menu--primary .icon--search {
    &.active {
      background: url("../img/bg-search-toggle-purple.svg");
    }
  }

  .wrap.wrap--gradient-dark {
    &::after {
      background: linear-gradient(to bottom, #2d2042 0%, #2d2042 33.3333%, #382851 33.3333%, #382851 66.6666%, #412f5b 66.6666%, #412f5b 100%);
      content: "";
      display: block;
      height: 30px;
      width: 100%;
    }
    @include breakpoint(600px) {
      &::after {
        height: 60px;
      }
    }
  }

  .copyright {
    color: $white;
  }
}

.wells--404 {
  @include breakpoint(600px) {
    display: flex;
    .well {
      float: left;
      width: 48%;
      margin-right: 2%;
      &:last-child {
        margin-right: 0;
        margin-left: 2%;
      }
    }
  }
}
