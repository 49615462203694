.container-page-title {
  .posted-on a {
    color: lighten($gray--dark, 15%);
    text-decoration: none;
    @include font-size(16px);
    font-family: $secondary--font-family__bold;
  }
}

.container--post {
  @include breakpoint(800px) {
    padding-left: 250px;
  }
  @include breakpoint(1000px) {
    padding-right: 250px;
  }
  .content {
    clear: both;
  }
}

.post__meta {
  @include font-size(14px);
  display: block;
  margin-bottom: $base--spacing / 2;
  @extend .clearfix;
  @include breakpoint(800px) {
    position: relative;
    height: 0;
    margin-bottom: 0;
    left: -250px;
    width: 200px;
  }
  a {
    text-decoration: none;
    color: $gray--dark;
  }
  .avatar {
    border-radius: 2px;
    float: left;
    max-width: 100px;
    margin-right: $base--spacing / 2;
    @include breakpoint(400px) {
      max-width: 150px;
    }
    @include breakpoint(800px) {
      float: none;
    }
  }
}

.byline {
  margin-bottom: $base--spacing / 2;
  display: block;
}

.post__social {
  clear: both;
  @include breakpoint(400px) {
    clear: none;
    display: block;
  }
  @include breakpoint(800px) {
    padding-top: $base--spacing / 2;
    border-top: 1px solid $gray--medium;
    clear: none;
    display: block;
    width: auto;
    float: none;
  }
}

.pinboard-link-list {
  background: $white;
  border-top: 6px solid $gray--medium;
  border-bottom: 6px solid $gray--medium;
  margin-bottom: $base--spacing;
  color: lighten($black, 25%);
  a {
    color: $secondary--blue;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $secondary--purple;
    }
  }
}

.pinboard-link-item {
  padding: $base--spacing $base--spacing 0;
  border-bottom: 1px solid $gray--medium;
  &:last-child {
    border-bottom: 0;
  }
}

.pinboard-link-header {
  margin-bottom: $base--spacing / 2;
}

.pinboard-link-note {
  margin-bottom: $base--spacing / 2;
}

.pinboard-source {
  display: none;
}

.pinboard-source-link {
  &::before {
    content: url("../img/icon-link.svg");
    display: inline-block;
    width: 15px;
    padding-right: $base--spacing / 3;
    position: relative;
    height: 25px; // for IE9
    top: 2px;
  }
}
