.feature-block {
  padding: $base--spacing / 2 $base--spacing / 2 0;
  @include breakpoint(600px) {
    padding-top: $base--spacing * 2;
  }
}

.tagline {
  @include font-size(24px);
  @include breakpoint(600px) {
    @include font-size(36px);
  }
  color: $white;
  &.tagline-small {
    @include font-size(24px);
    font-family: $secondary--font-family__bold;
  }
}

.mission {
  color: $green--light;
  @include font-size(18px);
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
