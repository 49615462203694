/*
 * Class-Based grid system
 *
 */

/* If you want to use in Wordpress as shortcodes, add this to functions.php (or better yet in an include for shortcodes)

//so shortcodes don't get wrapped in p tags
remove_filter( 'the_content', 'wpautop' );
add_filter( 'the_content', 'wpautop' , 12);

function supcamp_40_grid_grid( $atts, $content = '' ) {

  extract( shortcode_atts( array(
    'columns' => '2'
  ), $atts ) );

  return '<div class="grid-columns grid-columns-' . $atts['columns'] . '">' . do_shortcode( $content ) . '</div>';
}
add_shortcode( 'grid', 'supcamp_40_grid_grid' );

function supcamp_40_grid_column( $atts, $content = '' ) {

  return '<div class="grid-column">' . do_shortcode( $content ) . '</div>';
}
add_shortcode( 'column', 'supcamp_40_grid_column' );

 */
 // Grids
 //
 // Simple grid system
 //
 // Styleguide 5.0.0

 // 16 Column Grid
 //
 // Simple 16 column grid
 //
 // Markup:
 // <div class="grid-columns">
 //   <div class="grid-column-4">
 //			Column 1.
 //   </div>
 //   <div class="grid-column-4">
 //			Column 2.
 //   </div>
 //   <div class="grid-column-4">
 //			Column 3.
 //   </div>
 //   <div class="grid-column-4">
 //			Column 4.
 //   </div>
 // </div>
 //
 // Styleguide 5.1.0

 // Spaced Grid
 //
 // Simple 16 column grid
 //
 // Markup:
 // <div class="grid-columns">
 //   <div class="grid-column-4 grid-column--padded">
 //			<div class="well">
 //       Column 1.
 //     </div>
 //   </div>
 //   <div class="grid-column-4 grid-column--padded">
 //			<div class="well">
 //       Column 2.
 //     </div>
 //   </div>
 //   <div class="grid-column-4 grid-column--padded">
 //			<div class="well">
 //       Column 3.
 //     </div>
 //   </div>
 //   <div class="grid-column-4 grid-column--padded">
 //			<div class="well">
 //       Column 4.
 //     </div>
 //   </div>
 // </div>
 //
 // Styleguide 5.2.0

 // Varied Width
 //
 // You can use various sized columns
 //
 // Markup:
 // <div class="grid-columns">
 //   <div class="grid-column-8 grid-column--padded">
 //     Column 1.
 //   </div>
 //   <div class="grid-column-4 grid-column--padded">
 //     Column 2.
 //   </div>
 //   <div class="grid-column-2 grid-column--padded">
 //     Column 3.
 //   </div>
 //   <div class="grid-column-2 grid-column--padded">
 //     Column 4.
 //   </div>
 // </div>
 //
 // Styleguide 5.3.0

$grid__columns: 16;

.grid-columns {
  @extend .clearfix;
	display: flex;
	.grid-column--padded {
		padding-left: $base--spacing/2;
    padding-right: $base--spacing/2;
		&:first-child {
			padding-left: 0;
		}
    &:last-child {
			padding-right: 0;
		}
	}
}

@for $i from 1 through $grid__columns {
	.grid-columns .grid-column-#{$i} {
		float: left;
		width: 100%;
		@include breakpoint(600px) {
			width: ($i / $grid__columns * 100%);
		}
	}
}

// Shortcode grid stuff

.container--columns {
	position: relative;
	padding: 0;
}

@include breakpoint(50em) {
	.container--columns {
		display: flex;
	}
	.column--half {
		float: left;
		width: 50%;
		.container {
			padding: $base--spacing;
		}
		.container--devices {
			padding-bottom: 400px;
		}
	}
	.column--half--spaced.first {
		padding-right: $base--spacing;
	}
	.column--half--spaced.last {
		padding-left: $base--spacing;
	}
	.container--devices {
		position: static;
	}
	.devices {
		position: absolute;
		bottom: 0;
		width: 50%;
		left: 0;
		margin-left: 0;
	}
}

.column--half.column--half--spaced.first {
	padding-bottom: $base--spacing;
	@include breakpoint(50em) {
		padding-bottom: 0;
	}
}

@include breakpoint(55em) {
	.column--third {
		float: left;
		width: 33.3%;
		word-wrap: break-word;
	}
	.column--third--spaced.first {
		padding-right: $base--spacing;
	}
	.column--third--spaced.last {
		padding-left: $base--spacing;
	}
}
