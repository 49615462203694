.container--title-box {
  padding: $base--spacing;
  @include breakpoint(600px) {
    padding-top: $base--spacing * 4;
  }
  text-align: center;
}

.title-box {
  border: 5px solid $black;
  @include font-size(21px);
  border-radius: 5px;
  margin: -1px;
  display: inline-block;
  font-family: $secondary--font-family;
  font-weight: bold;
  background: $white;
  &.title-box--chevron {
    border-right: 0;
    border-radius: 5px 0 0 5px;
  }
}

.title-box__icon {
  background: $black;
  float: left;
  display: block;
  position: relative;
  line-height: 1.5;
  height: 33px;
  > svg {
    position: relative;
    top: 3px;
    left: 3px;
  }
  .title-box__chevron {
    display: inline-block;
    svg {
      display: inline-block;
      width: 16.5px;
      top: -4px;
      right: -16px;
      position: absolute;
    }
    path {
      fill: $black;
    }
  }
  .title-box__icon-svg {
    top: 4px;
    left: 2px;
    position: relative;
  }
  path {
    fill: $white;
  }
}

.title-box__title {
  display: block;
  float: left;
  position: relative;
  @include font-size(18px, false);
  color: $black;
  padding: 3px $base--spacing / 3;
  text-transform: uppercase;
  .title-box--icon & {
    border-right: 0;
    border-radius: 5px 0 0 5px;
    padding-left: $base--spacing / 1.5;
  }
  .title-box__title-svg {
    display: inline-block;
    width: 16.5px;
    top: -4px;
    right: -16px;
    position: absolute;
    path {
      fill: $black;
    }
  }
}

.title-box--green {
  border-color: $secondary--green;
  .title-box__icon {
    background-color: $secondary--green;
  }
  .title-box__icon .title-box__chevron,
  .title-box__title-svg {
    path {
      fill: $secondary--green;
    }
  }
  .title-box__title {
    color: $secondary--green;
  }
}

.title-box--blue {
  border-color: $secondary--blue;
  .title-box__icon {
    background-color: $secondary--blue;
  }
  .title-box__icon .title-box__chevron,
  .title-box__title-svg {
    path {
      fill: $secondary--blue;
    }
  }
  .title-box__title {
    color: $secondary--blue;
  }
}

.title-box--purple {
  border-color: $secondary--purple;
  .title-box__icon {
    background-color: $secondary--purple;
  }
  .title-box__icon .title-box__chevron,
  .title-box__title-svg {
    path {
      fill: $secondary--purple;
    }
  }
  .title-box__title {
    color: $secondary--purple;
  }
}

.title-box--red {
  border-color: $secondary--red;
  .title-box__icon {
    background-color: $secondary--red;
  }
  .title-box__icon .title-box__chevron,
  .title-box__title-svg {
    path {
      fill: $secondary--red;
    }
  }
  .title-box__title {
    color: $secondary--red;
  }
}

.title-box--red-light {
  border-color: $secondary--red-light;
  .title-box__icon {
    background-color: $secondary--red-light;
  }
  .title-box__icon .title-box__chevron,
  .title-box__title-svg {
    path {
      fill: $secondary--red-light;
    }
  }
  .title-box__title {
    color: $secondary--red-light;
  }
}

.title-box--teal {
  border-color: $secondary--teal;
  .title-box__icon {
    background-color: $secondary--teal;
  }
  .title-box__icon .title-box__chevron,
  .title-box__title-svg {
    path {
      fill: $secondary--teal;
    }
  }
  .title-box__title {
    color: $secondary--teal;
  }
}

.category-tabs {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: $secondary--font-family__regular;
  }
  li {
    display: inline-block;
    padding: 3px $base--spacing / 2 0;
  }
  .active a {
    color: $secondary--blue-dark;
    &::after {
      height: 3px;
    }
  }
  a {
    color: $secondary--blue;
    text-decoration: none;
    display: block;
    position: relative;
    transition: color .3s;
    &::after {
      content: "";
      width: 100%;
      height: 0;
      background: $secondary--blue-dark;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: height .3s;
    }
    &:hover,
    &:active,
    &:focus {
      color: $secondary--blue-dark;
      &::after {
        height: 3px;
      }
    }
  }
}
