// Wells
//
// Boxes
//
// Styleguide 4.0.0

// Well
//
// Boxes for content
//
// Markup:
// <div class="well">
//   A box with content in it.
// </div>
//
// Styleguide 4.1.0
.well {
  padding: $base--spacing / 2;
  background: $white;
  border-top: 6px solid $gray--medium;
  border-bottom: 6px solid $gray--medium;
  margin-bottom: $base--spacing;
  color: lighten($black, 25%);
  p {
    @include font-size(18px);
  }
  @include breakpoint(700px) {
    padding: $base--spacing * 2;
  }
  a,
  button {
    color: $secondary--blue;
    font-weight: bold;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $secondary--blue-dark;
      text-decoration: underline;
    }
  }
  a.btn {
    color: $white;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  > *:last-child {
    margin-bottom: 0;
  }
  &.well--shadowed {
    border: 0;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba($white, .5);
      position: absolute;
      right: -10px;
      top: -10px;
      z-index: -1;
      box-shadow: 10px -10px 0 rgba($white, .2);
    }
  }
  &.well--purple {
    background: $secondary--purple;
    border: 0;
    color: $white;
    a {
      color: $green--light;
    }
    a.btn {
      color: $white;
    }
  }
  &.well--no-padding {
    padding: 0;
  }
  &.well--full-border {
    border: 2px solid $gray--medium;
  }
  &.well--centered {
    color: $secondary--purple;
    font-weight: bold;
    text-align: center;
  }
  .flex-row {
    @extend .clearfix;
    border-bottom: 4px solid $gray--medium;
    &:last-child {
      border-bottom: 0;
    }
    @include breakpoint(700px) {
      display: flex;
      align-items: center;
    }
  }
  .flex-column {
    border-bottom: 4px solid $gray--medium;
    padding: $base--spacing;
    flex-grow: 1;
    @include breakpoint(700px) {
      padding: $base--spacing * 3;
      border-bottom: 0;
      border-right: 4px solid $gray--medium;
    }
    div {
      display: block; // cancel out flexbox
    }
    &:last-child {
      border: 0;
    }
  }
  @include breakpoint(700px) {
    .flex-column-half {
      float: left;
      width: 50%;
    }
  }
}

.alert {
  padding: $base--spacing / 2;
  margin-bottom: $base--spacing;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable {
  padding-right: 35px;
}

.alert-dismissable .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
// Alerts
//
// Markup:
// <div class="alert alert-success">
//   <h3>Success</h3>
//   A box with <a href="#">content</a> in it.
// </div>
// <div class="alert alert-info">
//   <h3>Info</h3>
//   A box with <a href="#">content</a> in it.
// </div>
// <div class="alert alert-warning">
//   <h3>Warning</h3>
//   A box with <a href="#">content</a> in it.
// </div>
// <div class="alert alert-danger">
//   <h3>Danger</h3>
//   A box with <a href="#">content</a> in it.
// </div>
//
// Styleguide 4.2.0
.alert-success {
  background-color: #6fc067;
  border-color: #668c62;
  color: darken(#668c62, 20%);
}

.alert-success hr {
  border-top-color: #668c62;
}

.alert-success .alert-link {
  color: #668c62;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

.podcast_player {
  max-width: 600px;
  margin-bottom: 0;
  br {
    display: none;
  }
  .mejs-container .mejs-controls { // nested for specificity to override plugins styles
    background: $secondary--blue;
    div {
      @include font-size(14px, false);
    }
  }
  .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,
  .mejs-controls .mejs-time-rail .mejs-time-loaded {
    background: lighten($secondary--blue, 60%);
  }
}

.podcast_meta {
  @include font-size(12px);
  margin-bottom: $base--spacing / 2;
}
