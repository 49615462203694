.wrap--portfolio {
  border-bottom: 4px solid $gray--medium;
  .page-template-page-home & {
    border-bottom-width: 0;
    @include breakpoint(1000px) {
      border-bottom-width: 4px;
    }
  }
}

.portfolio {
  padding: $base--spacing 0 $base--spacing * 6;
  .page-template-page-home & {
    padding-bottom: 0;
  }
  .portfolio__example {
    position: absolute;
    width: $width--6;
    bottom: 0;
    right: -145px;
    max-width: 286px;
    max-height: 400px;
    overflow: hidden;
    border-bottom: 0;
  }
}

.portfolio__item {
  overflow: hidden;
  padding: $base--spacing $base--spacing / 2;
  position: relative;
  border-bottom: 4px solid $gray--medium;
  @include breakpoint(1000px) {
    padding: $base--spacing * 3 $base--spacing / 2 $base--spacing $base--spacing * 2;
    float: left;
    width: $width--6;
    .page-template-page-home & {
      border-bottom-width: 0;
    }
    &:nth-child(2n+1) {
      clear: both;
      border-right: 4px solid $gray--medium;
      padding: $base--spacing * 3 $base--spacing * 2 $base--spacing $base--spacing / 2;
    }
  }
  @include breakpoint(2000px) {
    max-width: 2000px;
  }
  &.portfolio__item--empty {
    background: $gray--medium;
    color: $gray--light;
    text-align: center;
    align-items: center;
    padding: $base--spacing * 2;
    display: none;
    @include breakpoint(1000px) {
      display: block;
      display: flex;
    }
    .portfolio__title {
      @include font-size($font-size--h2);
    }
    .entry-header {
      width: 100%;
    }
  }
}

.portfolio__header {
  @include font-size(14px);
  border-radius: 4px;
  display: inline-block;
  font-family: $secondary--font-family;
  letter-spacing: 1px;
  line-height: 1;
  padding: 14px 30px 12px;
  text-transform: uppercase;
  margin-bottom: 0;
  &.portfolio__header--gray-light {
    background: $gray--medium;
    color: $white;
  }
}

.portfolio__items {
  @include breakpoint(1000px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.portfolio__summary {
  padding-right: 30%;
  min-height: 400px;
}

.portfolio__title {
  @include font-size(28px);
  font-family: $secondary--font-family__bold;
  margin-bottom: $base--spacing / 2;
  a {
    color: $black;
    text-decoration: none;
  }
}

.portfolio__website {
  @include font-size(15px);
  color: $secondary--blue;
  display: inline-block;
  font-family: $base--font-family;
  font-weight: bold;
  margin-bottom: $base--spacing / 2;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
  svg {
    position: relative;
    top: 5px;
    width: 20px;
  }
  path {
    fill: $secondary--blue;
  }
}

.portfolio__example {
  background: #d2d2d2;
  border: 3px solid #c4c4c3;
  display: inline-block;
  box-shadow: -20px 35px 100px 0 rgba($base--color, .35);
  &::before {
    content: url("../img/address-bar.svg");
    width: $width--12;
    display: block;
    height: auto;
    margin-bottom: -4px; // there was extra space under the svg for some reason
  }
  img {
    display: block;
  }
}

.portfolio-promo {
  @include breakpoint(800px) {
    display: flex;
  }
  @extend .clearfix;
}

.portfolio-promo__item {
  padding: $base--spacing;
  border-bottom: 2px solid $gray--medium;
  &:last-child {
    border-bottom: 0;
  }
  .portfolio__website {
    display: block;
  }
  @include breakpoint(800px) {
    float: left;
    width: 50%;
    border-right: 2px solid $gray--medium;
    border-bottom: 0;
    &:last-child {
      border-right: 0;
    }
  }
}
