// Links
//
// Styleguide 10.0.0

// Text Links
//
// Markup:
// <a href="#">Link Text</a>
//
// Styleguide 10.1.0
a {
  color: #336767;
  &:hover,
  &:focus {
  	text-decoration: none;
  }
}

a:visited {

}

a:hover, a:focus {

}

a:active {
  text-decoration: none;
}

.link--icon {
  color: $green--light;
  font-weight: bold;
  text-decoration: none;
  svg {
    margin-bottom: -5px;
  }
  path {
    fill: $green--light;
  }
}

// Buttons
//
// Markup:
// <a class="btn" href="#">Button</a>
//
// Styleguide 10.2.0
