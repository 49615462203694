$mantra-breakpoint: 1000px;
.mantra {
  padding: $base--spacing ($base--spacing / 2);
  position: relative;
  @include breakpoint($mantra-breakpoint) {
    padding: $base--spacing * 3 ($base--spacing / 2);
  }
}

.mantra__header--border {
  margin: $base--spacing 0;
  width: 100%;
  position: relative;
  @include breakpoint($mantra-breakpoint) {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -35px;
  }
  &::before {
    background: $gray--medium;
    content: "";
    height: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -1px;
  }
}

.mantra__header {
  @include font-size(14px);
  background: url("../img/mantra-header-bg.svg") no-repeat;
  background-size: cover;
  display: inline-block;
  color: $gray--light;
  font-family: $secondary--font-family;
  letter-spacing: 1px;
  line-height: 1;
  min-height: 70px;
  margin: 0;
  min-width: 190px;
  padding: 30px 30px 25px;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
  left: 50%;
  margin-left: -(190px / 2);
}

.mantra__items {
  text-align: center;
  @extend .clearfix;
  @include breakpoint($mantra-breakpoint) {
    // display: flex;
  }
}

.mantra__item {
  padding: 0 $base--spacing;
  @include breakpoint($mantra-breakpoint) {
    float: left;
    width: $width--4;
    padding: $base--spacing * 2;
    &:nth-child(3n+1) {
      clear: both;
    }
    &:nth-child(2) {
      top: -$base--spacing * 2;
      position: relative;
    }
    &:nth-child(5) {
      bottom: -$base--spacing * 2;
      position: relative;
    }
  }
}

.mantra__title {
  @include font-size(28px);
  font-family: $secondary--font-family__light;
}

.mantra__summary {
  strong {
    font-family: $secondary--font-family__bold;
  }
}
