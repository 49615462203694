html {
  background: $gray--light;
  border: 4px solid $green--light;
  font-family: $base--font-family;
  display: flex;
  min-height: 100%;
}

body {
  background: $gray--light;
  border: 4px solid $gray--light;
  min-height: 100%;
}


.header-wrap {
  @include breakpoint(775px) {
    display: flex;
    align-items: center;
  }
}

.logo {
  text-align: center;
  width: 100%;
  float: left;
  svg {
    max-width: 270px;
    width: 100%;
    max-height: 85px; // for IE10
    @include breakpoint(775px) {
      display: block;
      float: left;
    }
  }
  @include breakpoint(775px) {
    width: 25%;
  }
}

/**
  * Primary
  */
.menu--primary {
  @extend .clearfix;
  font-family: $secondary--font-family;
  width: 100%;
  @include breakpoint(775px) {
    float: right;
    padding: 0;
    width: 75%;
  }
  ul,
  ol {
    @include font-size(14px);
    float: right;
    list-style: none;
    list-style-image: none;
    padding: 0;
    margin: 0;
    text-align: center;
    width: 100%;
    @include breakpoint(775px) {
      text-align: right;
    }
  }

  li {
    padding: 0 2%;
    display: inline-block;
    &:last-child { // add class instead of :last-child
      margin: 0;
      padding-right: 0;
    }
  }
  @include breakpoint(450px) {
    li {
      display: inline-block;
    }
  }
  .menu-item--search {
    position: relative;
    width: 40px; // gives it layout :/
  }
  a {
    display: block;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    transition: color .3s;
    color: $white;
    &::after {
      content: "";
      width: 100%;
      height: 0;
      background: $green--light;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: height .3s;
    }
    &:hover,
    &:active,
    &:focus {
      color: $green--light;
      &::after {
        height: 3px;
      }
    }
    &.btn {
      border: none;
      color: $white;
      margin-top: $base--spacing/2;
      @include breakpoint(775px) {
        margin-top: 0;
      }
      &:hover,
      &:focus {
        &::after {
          display: none;
        }
      }
    }
  }
  .icon--search {
    outline: 0;
    position: absolute;
    top: -31px;
    left: 0;
    padding: 16px 10px 10px; // precise to fit svg background in
    text-align: center;
    line-height: 1;
    color: $white;
    &:hover,
    &:focus {
      path {
        fill: $green--light;
      }
    }
    &.active {
      background-image: url("../img/bg-search-toggle.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
