.about {
  @include font-size(21px);
  padding-top: $base--spacing;
  padding-bottom: $base--spacing;
  margin: 0 auto;

  p {
    @include font-size(18px);
  }

  strong {
    color: $secondary--blue;
  }
}

.about__title {
  @include font-size(22px);
  margin: $base--spacing / 2 auto 20px;
  max-width: 300px;
  background: $green--dark;
  border: 2px solid $green--light;
  color: $white;
  box-shadow: 0 0 0 4px $green--dark;
  padding: ($base--spacing / 4) 0;
  text-align: center;
  text-transform: uppercase;

  @include breakpoint(768px) {
    @include font-size(32px);
    margin: $base--spacing / 2 auto 20px;
    max-width: 600px;
  }
}

.about__position {
  @include font-size(14px);
  margin-bottom: 0;
  font-family: $secondary--font-family__light;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
}

.about__link {
  color: $green--light;
  display: block;
  text-align: center;
  text-decoration: none;
}

.about__partner {
  @extend .clearfix;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: $base--spacing;
  align-items: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  &:nth-child(2n) {
    flex-direction: row;
    @include breakpoint(1000px) {
      padding: 0 0 0 $base--spacing / 2;
    }
    .about__img {
      float: left;
      padding-right: $base--spacing;
      padding-left: 0;
    }
    .about__detail {
      float: right;
    }
  }
  @include breakpoint(1000px) {
    float: left;
    width: $width--6;
    padding: 0 $base--spacing / 2 0 0;
  }
}

.about__img {
  float: right;
  padding-left: $base--spacing;
  position: relative;
  img {
    display: block;
  }
}

.about__detail {
  margin-top: 10px; // looked uneven when vertically centered for some reason
  text-align: center;
  width: 71%; // arbitrary amount to fill up container
  float: left;
}
