.wp-caption {
  background: $gray--medium;
  padding: 3px;
  margin-bottom: $base--spacing;
  max-width: 100%;
}

.wp-caption-text {
  color: lighten($gray--dark, 15%);
  font-style: italic;
  text-align: center;
  margin-bottom: 4px;
  @include font-size(14px, false);
}

/* =WordPress Core
-------------------------------------------------------------- */
// .alignnone {
//   margin: 0 20px 20px 0;
// }

.aligncenter,
div.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignright {
  float: right;
  margin: 0 0 $base--spacing $base--spacing;
}

.alignleft {
  float: left;
  margin: 0 $base--spacing $base--spacing 0;
}

a img.alignright {
  float: right;
  margin: 0 0 $base--spacing $base--spacing;
}

a img.alignnone {
  margin: 0 $base--spacing $base--spacing 0;
}

a img.alignleft {
  float: left;
  margin: 0 $base--spacing $base--spacing 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// .wp-caption {
//    background: #fff;
//    border: 1px solid #f0f0f0;
//    max-width: 96%; /* Image does not overflow the content area */
//    padding: 0 3px 10px;
//    text-align: center;
// }
//
// .wp-caption.alignnone {
//    margin: 0 $base--spacing $base--spacing 0;
// }
//
// .wp-caption.alignleft {
//    margin: 0 $base--spacing 20px 0;
// }
//
// .wp-caption.alignright {
//    margin: 0 0 20px 20px;
// }
//
// .wp-caption img {
//   border: 0 none;
//   height: auto;
//   margin: 0;
//   max-width: 98.5%;
//   padding: 0;
//   width: auto;
// }
//
// .wp-caption p.wp-caption-text {
//   font-size: 11px;
//   line-height: 17px;
//   margin: 0;
//   padding: 0 4px 0;
// }

/* Text meant only for screen readers. */
.screen-reader-text {
  @extend .visuallyhidden;
}

.posts-navigation {
  @extend .container;
  @extend .container--padded;
  text-align: center;
}

.nav-previous,
.nav-next {
  display: inline-block;
  padding: 0 $base--spacing / 2;
  a {
    @extend .btn;
    margin-bottom: $base--spacing / 2;
  }
}

.gallery-item {
  float: left;
  width: 33%;
  padding-right: $base--spacing;
  padding-bottom: $base--spacing;
}
