// Tables
//
// Markup:
// <table>
//   <thead>
//      <tr>
//        <th>Head 1</th>
//        <th>Head 2</th>
//        <th>Head 3</th>
//        <th>Head 4</th>
//        <th>Head 5</th>
//      </tr>
//   </thead>
//   <tbody>
//      <tr>
//        <td>Content</td>
//        <td>Content</td>
//        <td>Content</td>
//        <td>Content</td>
//        <td>Content</td>
//      </tr>
//      <tr>
//        <td>Content</td>
//        <td>Content</td>
//        <td>Content</td>
//        <td>Content</td>
//        <td>Content</td>
//      </tr>
//      <tr>
//        <td>Content</td>
//        <td>Content</td>
//        <td>Content</td>
//        <td>Content</td>
//        <td>Content</td>
//      </tr>
//   </tbody>
// </table>
//
// Styleguide 8.0.0

table {
  width: 100%;
}
td, th {
  margin: 0;
  padding: 5px;
}
th {
  font-family: $secondary--font-family;
  font-weight: $font-weight__normal;
  text-align: left;
  @include font-size(18px);

}
tr:nth-child(2n) {
  background: rgba($base--border, 0.1);
}
tbody {
  tr {
    &:last-child {
      border: 0;
    }
  }
}
thead {
  tr {
    border-bottom: 1px solid $base--border;
  }
}
tfoot {
  border-top: 1px solid $base--border;
}
