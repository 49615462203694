/***********************
Menus
***********************/

.secondary--horizontal {
  li {
    display: inline-block;
  }
}

.secondary--equal-width {
  ul,
  ol {
    display: table;
    width: 100%;
  }
  li {
    text-align: center;
    display: table-cell;
  }
}

.menu--social {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
    padding: 0 3px;
  }
  a:hover,
  a:focus {
    path {
      fill: $secondary--purple;
    }
  }
  path {
    fill: $secondary--blue;
  }
}
