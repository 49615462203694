.main--content {
  padding-top: $base--spacing;
  padding-bottom: $base--spacing;
  @include breakpoint(600px) {
    padding-top: $base--spacing * 2;
    padding-bottom: $base--spacing * 2;
    overflow: hidden;
  }
}

// TODO: think about renaming this
.main {
  clear: both;
}

.container-page-title {
  padding-bottom: $base--spacing / 2;
  border-bottom: 1px solid $gray--medium;
  margin-bottom: $base--spacing;
  padding-left: 0;
  padding-right: 0;
  .list--icons {
    margin-bottom: 0;
  }
}

.page-title {
  @include font-size(24px);
  @include breakpoint(600px) {
    @include font-size(36px);
  }
  font-family: $base--font-family;
  @include font-weight(bold);
  margin-bottom: 0;
}

.content {
  blockquote {
    color: $secondary--teal;
    @include font-size(29px);
    margin-bottom: 0;
    margin-top: $base--spacing * 2;
    margin-bottom: $base--spacing * 2;
    &.blockquote--small {
      &,
      p {
        @include font-size(18px);
      }
    }
    p {
      @include font-size(29px);
      line-height: 1.5;
      @include font-weight(bold);
      font-style: italic;
    }
    p:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: "";
      width: 100px;
      height: 4px;
      background: $secondary--teal;
      display: block;
      margin-bottom: $base--spacing - 10px;
    }
    &::after {
      content: "";
      width: 100px;
      height: 4px;
      background: $secondary--teal;
      display: block;
      margin-top: $base--spacing;
    }
  }
}
